let UserPost = function (tokenClient, serviceAddr) {
    let TokenClient = tokenClient;
    let ServiceBaseAddress = serviceAddr;
    /**
     * 获取所有职位
     * @param {any} success
     * @param {any} error
     */
    this.Posts = function (organizationId,success, error) {
        var url = ServiceBaseAddress + '/api/UserPost/Organization/' + organizationId;
        TokenClient.Get(url, true, null, null,
            function (data) {
                if (success) {
                    var jsonObj = JSON.parse(data);
                    success(jsonObj);
                }
            }, error);
    }

    this.categoryPosts = function (categoryId, success, error) {
        var url = ServiceBaseAddress + '/api/UserPost/Category/' + categoryId;
        TokenClient.Get(url, true, null, null,
            function (data) {
                if (success) {
                    var jsonObj = JSON.parse(data);
                    success(jsonObj);
                }
            }, error);
    }
}

export { UserPost }
