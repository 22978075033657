<template>
    <div class="personalIndex xi">
        <nh-com></nh-com>
        <div class="banner">
            <div class="banner-wrap clearfix">
                <div class="banner-text">
                    <h3>个人中心</h3>
                    <p>当前位置：
                        <router-link to="/index">首页</router-link><i class="el-icon-arrow-right"></i><a>个人中心</a></p>
                </div>
            </div>
        </div>
        <div class="personal-content">
            <div class="personal-box clearfix">
                <div class="personal-left">
                    <hd-img></hd-img>
                </div>
                <div class="personal-right" v-if="personalData != null && Object.keys(personalData).length != 0">
                    <el-form :label-position="labelPosition" label-width="80px" :model="personalData" class="personalForm" >
                        <div class="el-form-tit">
                            <i class="iconfont">&#xe62d;</i><span>个人<em>资料</em></span>
                        </div>
                        <el-row :gutter="20">
                            <el-col :span="12">
                                <el-form-item label="姓名">
                                    <el-input v-model="personalData.userName" :disabled="isReadonly"></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="12">
                                <el-form-item label="职称">
                                      <el-select v-model="personalData.userTitleId" placeholder="请选择" v-if="titleOptions" :disabled="isReadonly">
                                        <el-option
                                        v-for="item in titleOptions"
                                        :key="item.id"
                                        :label="item.userTitleName"
                                        :value="item.id">
                                        </el-option>
                                    </el-select>
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <el-row :gutter="20">
                            <el-col :span="12">
                                <el-form-item label="电话">
                                    <el-input v-model="personalData.phone" disabled></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="12">
                                <el-form-item label="职务">
                                    <el-select v-model="personalData.userPostId" placeholder="请选择" v-if="postOptions" :disabled="isReadonly">
                                        <el-option
                                        v-for="item in postOptions"
                                        :key="item.id"
                                        :label="item.userPostName"
                                        :value="item.id">
                                        </el-option>
                                    </el-select>
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <div class="el-form-tit">
                            <i class="iconfont">&#xe6db;</i><span>单位<em>信息</em></span>
                        </div>
                         <el-form-item label="所属单位" v-if="personalData.organization">
                                <el-input v-model="personalData.organization.name" disabled></el-input>
                         </el-form-item>
                        <el-form-item label="所在科室" v-if="personalData.department">
                            <el-input v-model="personalData.department.name" disabled></el-input>
                        </el-form-item>
                         <el-form-item label="办公电话">
                                    <el-input v-model="personalData.telephone" :disabled="isReadonly"></el-input>
                        </el-form-item>
                        <!-- <el-row :gutter="20">
                            <el-col :span="16">
                                <el-form-item label="办公电话">
                                    <el-input v-model="rganizationOptions.telephone" :disabled="isReadonly"></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="8">
                                <el-form-item label="分机">
                                    <el-input v-model="personalData.extension" :disabled="isReadonly"></el-input>
                                </el-form-item>
                            </el-col>
                        </el-row> -->
                        <el-form-item label="详细地址">
                            <el-input v-model="personalData.address" :disabled="isReadonly"></el-input>
                        </el-form-item>
                        <div class="el-form-tit">
                            <i class="iconfont">&#xe62c;</i><span>个人<em>简介</em></span>
                        </div>
                        <el-form-item label="请输入个人简介">
                            <!-- <quill-editor
                                    :disabled="isReadonly"
                                    v-model="personalData.abstract"
                                    ref="myQuillEditor"
                                    :content="personalData.abstract"
                                    @change="onEditorChange($event)"
                            /> -->
                            <el-input :disabled="isReadonly" type="textarea" :rows="2" placeholder="请输入内容" v-model="personalData.abstract"> </el-input>
                        </el-form-item>
                          <el-form-item v-if="!isSave">
                            <el-button type="primary" @click="onEdit">编辑</el-button>
                        </el-form-item>
                        <el-form-item v-if="isSave">
                            <el-button type="primary" @click="onSubmit">保存</el-button>
                            <el-button type="primary" @click="onCancel">取消编辑</el-button>
                        </el-form-item>
                    </el-form>
                </div>
            </div>
        </div>
        <ft-com></ft-com>
    </div>
</template>

<script>
    import ftCom from '../../components/footer'
    import nhCom from "../../components/navheader";
    import hdImg from "../../components/headerImg";
    import { getUserInfo,userInfoLoad } from "../../utils/userInfo";
    import { UserPost } from "../../components/User/UserPost"
    import { UserTitle } from "../../components/User/UserTitle"
    import { UserInfo } from "../../components/User/UserInfo"
    export default {
        name: "personalIndex",
        inject:["reload"],
        components: {
            ftCom,
            nhCom,
            hdImg
        },
        data() {
            var post=new UserPost(this.TokenClient, this.Services.Authorization)
            var title=new UserTitle(this.TokenClient, this.Services.Authorization)
            var user=new UserInfo(this.TokenClient, this.Services.Authorization)
            return {
                labelPosition: 'top',
                personalData: {},
                isSave:false,
                isReadonly:true,
                titleOptions:[],  //职称
                postOptions:[], //职位
                postDomain:post,
                titleDomain:title,
                userDomain:user,
                photo:getUserInfo().photo,
                categoryId:getUserInfo().categoryId,
                organizationId:getUserInfo().organizationId
            };
        },
        methods:{
            LoadUserInfo(){
                var _this = this;
                _this.userDomain.UserMes(function (data) {
                    _this.personalData=data.data;
                    _this.init();
                },function (error) {
                    _this.$router.push({
                        path: "/Login",
                        query: {}
                    });
                })
            },
            onSubmit() {
                var _this = this;
                var editeItem=this.personalData
                this.userDomain.Edit(editeItem.id, editeItem.userName, editeItem.phone, editeItem.userTitleId, editeItem.userPostId,
                    editeItem.roleId, editeItem.organizationId, editeItem.departmentId, editeItem.telephone, editeItem.address,
                    editeItem.abstract, _this.photo, function (data) {
                    _this.$message({
                            type: 'success',
                            message: '操作成功!'
                        });
                        userInfoLoad(editeItem.userName, editeItem.phone, editeItem.userTitleId, editeItem.userPostId, editeItem.telephone,
                            editeItem.address, editeItem.abstract, null)
                        _this.reload()
                },
                function(error){
                    console.log(error)
                    _this.$message.error('系统出错!保存信息失败。');
                })
            },
            onEdit(){
                this.isSave=true
                this.isReadonly=false
            },
            onCancel(){
                this.isSave= false
                this.isReadonly=true
            },
            init(){
                var _this = this;
                //职称
                _this.titleDomain.Titles(function(data){
                    _this.titleOptions=data.data
                },
                function(error){
                    console.log(error)
                })
                //职务
                console.log(_this.organizationId)
                _this.postDomain.Posts(_this.organizationId,function(data){
                     _this.postOptions=data.data
                },
                function(error){
                    console.log(error)
                })
            },
            onEditorChange({ editor, html, text }) {
                this.content = html;
            },
        },
    mounted() {
            this.LoadUserInfo();
        }
    }
</script>

<style scoped>
    @import "../../assets/css/footer.css";

    .banner {
        width: 100%;
        height: 160px;
        background: url("../../assets/img/news_banner.jpg") center no-repeat;
    }

    .banner-wrap {
        position: relative;
        width: 1200px;
        height: 100%;
        margin: 0 auto;
    }

    .banner-text {
        float: left;
    }

    .banner-text h3 {
        padding-top: 35px;
        font-size: 26px;
        font-weight: normal;
        color: #ffffff;
    }

    .banner-text p {
        font-size: 14px;
        color: #ffffff;
        margin-top: 20px;
    }

    .banner-text p a {
        color: #ffffff;
        font-size: 14px;
        margin: 0 5px;
    }
    /**/
    .personal-content{
        background: #f7f7f7;
        padding: 40px 0;
    }
    .personal-box{
        width: 1200px;
        margin: 0 auto;
    }
    .personal-left{
        float: left;
    }


    .personal-right{
        float: right;
        width: 900px;
        padding: 20px 30px;
        background: #FFFFFF;
        box-sizing: border-box;
    }
    .el-form-tit{
        margin-top: 10px;
        margin-bottom: 20px;
    }
    .el-form-tit .iconfont{
        color: #04a290;
        font-size: 24px;
        margin-right: 5px;
    }
    .el-form-tit span{
        display: inline-block;
        font-size: 24px;
        font-weight: 700;
        color: #333333;
    }
    .el-form-tit span em{
        font-style: normal;
        color: #04a290;
    }
</style>
<style>
    .personal-center{
        display: none;
    }
    .personalForm .el-form-item__label{
        line-height: 1;
    }
    .personal-content .ql-container{
        height: 200px;
    }
</style>
